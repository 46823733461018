import * as React from "react"
import { Link, graphql } from "gatsby"
import { Breadcrumb } from "antd"
import Bio from "../components/bio"
import Layout from "../components/blogLayout"
import Seo from "../components/seo"
import MainLayout from "../components/mainLayout/index"
import * as styles from "../styles/blogPost.module.less"
import useStore from "../store/index"
import kebabCase from "lodash/kebabCase"

const BlogPostTemplate = ({ data, location }) => {
  const { lastPathName, tag, firstTag, key } = useStore()
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  // const { previous, next } = data
  console.log(post)
  return (
    <MainLayout path={location.pathname}>
      <div className={styles.pullDown}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">前端</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/tags/${kebabCase(firstTag)}`}>{key}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${lastPathName}`}>{tag}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{post.frontmatter.title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles.wrapContent}>
        <Layout location={location} title={siteTitle}>
          <Seo
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
          />
          <article
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header>
              <h1 itemProp="headline">{post.frontmatter.title}</h1>
              <p>
                <b>{post.frontmatter.author}</b> {post.frontmatter.date}
              </p>
            </header>
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
            />
            <hr />
            <footer>
              <Bio />
            </footer>
          </article>
          {/* <nav className="blog-post-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav> */}
        </Layout>
      </div>
    </MainLayout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        author
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
